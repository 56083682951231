import request from '@/utils/request'

// 发送手机验证码
export const getPhoneCode = params =>
  request.post('/pipe-api/auth/sndCode', params)

//图形验证码
export const getVerifyCode = params =>
  request.get('/pipe-api/auth/formula', params)

// 注册
export const getRegister = params =>
  request.post('/pipe-api/upUpSign/register', params)

//登录
export const getLogin = params =>
  request.post('/pipe-api/upUpSign/login', params)
