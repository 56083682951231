<template>
  <div class="login">
    <div class="login-container">
      <div class="bgImg">
        <img src="./image/login.png" alt="电子合同" width="631" />
        <span>电子签约平台</span>
      </div>

      <div class="login-right">
        <p class="login-code">
          <span :class="{ active: active === '1' }" @click="active = '1'">
            密码登录
          </span>
          <span :class="{ active: active === '2' }" @click="active = '2'">
            验证码登录
          </span>
        </p>
        <div class="password-login" v-show="active === '1'">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            style="hright:150px;!import"
          >
            <el-form-item prop="name">
              <el-input
                placeholder="请输入手机号码"
                prefix-icon="el-icon-user"
                v-model="form.name"
                type="name"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                placeholder="密码"
                prefix-icon="el-icon-lock"
                v-model.trim="form.password"
                type="password"
              />
            </el-form-item>
            <el-form-item prop="pictureCode">
              <el-input
                v-model.trim="form.pictureCode"
                placeholder="图形验证码"
                prefix-icon="iconfont icon-yanzhengyanzhengma"
                type="pictureCode"
                style="width: 200px"
              ></el-input>
              <img
                :src="verifyUrl"
                alt="verifyCode"
                class="verifyImg"
                @click="changeVerifyCode"
              />
            </el-form-item>
          </el-form>
          <span
            class="forget"
            @click="() => this.$router.push('/forget-password')"
          >
            忘记密码
          </span>
          <div class="login-tips">
            <el-checkbox v-model="checked"></el-checkbox>
            <p class="tip">
              我已阅读并同意
              <span @click="dialogVisible = true">《乐易签服务协议》</span>
              、
              <span @click="dialogVisible1 = true">《隐私政策》</span>
              和
              <span @click="dialogVisible2 = true">《数字证书使用协议》</span>
            </p>
          </div>
          <el-button
            type="primary"
            class="login-button"
            @click="_passWordLogin"
          >
            登录
          </el-button>
          <p class="goRegister">
            <span>没有账号？</span>
            <span @click="ljzc">立即注册</span>
          </p>
        </div>
        <div class="password-login" v-show="active === '2'">
          <el-form ref="form2" :model="form2" :rules="rules">
            <el-form-item prop="phone">
              <el-input
                placeholder="请输入手机号码"
                prefix-icon="el-icon-user"
                v-model="form2.phone"
                type="phone"
              ></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                placeholder="验证码"
                style="width: 205px"
                prefix-icon="iconfont icon-yanzhengyanzhengma"
                v-model.trim="form2.code"
              />
              <span class="getCode" @click="getCode">
                {{ show ? '获取验证码' : `${count}s后重新获取` }}
              </span>
            </el-form-item>
            <el-form-item prop="pictureCode">
              <el-input
                v-model.trim="form2.pictureCode"
                placeholder="图形验证码"
                prefix-icon="iconfont icon-yanzhengyanzhengma"
                type="pictureCode"
                style="width: 200px"
              ></el-input>
              <img
                :src="verifyUrl"
                alt="verifyCode"
                class="verifyImg"
                @click="changeVerifyCode"
              />
            </el-form-item>
          </el-form>
          <div class="login-tips">
            <el-checkbox v-model="checked"></el-checkbox>
            <p class="tip">
              我已阅读并同意
              <span @click="dialogVisible = true">《乐易签服务协议》</span>
              、
              <span @click="dialogVisible1 = true">《隐私政策》</span>
              和
              <span @click="dialogVisible2 = true">《数字证书使用协议》</span>
            </p>
          </div>
          <el-button type="primary" class="login-button" @click="codeLogin">
            登录
          </el-button>
          <p class="goRegister">
            <span>没有账号？</span>
            <span @click="ljzc">立即注册</span>
          </p>
        </div>
        <!-- 乐易签服务协议 -->
        <el-dialog
          title="乐易签服务协议"
          :visible.sync="dialogVisible"
          :fullscreen="true"
          width="30%"
          :center="true"
          :before-close="handleClose"
        >
          <b class="bold">一、声明与承诺</b>
          <p>
            <span class="bold">
              （一）本协议已对与您的权益有或可能具有重大关系的条款，及对北京联想科技服务有限公司（以下简称“本公司”）具有
              或可能具有免责或限制责任的条款用粗体斜体和下划线字予以标注，请您注意。
            </span>
            您确认，在您注册成为本公司电子签约云平台（以下简称“本公司平台”或“平台”）
            用户以接受本服务，或您以其他本公司允许的方式实际使用本服务前，您已充分阅读、理解并接受本协议的全部内容，一旦您使用本服务，即表示您同意遵循本协议之所有约定。
            <span class="bold">
              阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。
            </span>
          </p>
          <p>
            <span class="bold">
              （二）您同意，本公司有权随时对本协议内容进行单方面的变更，并以在本平台公告的方式予以公布，无需另行单独通知您；若您在本协议内容公告变更后继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用本服务；若您不同意修改后的协议内容，您应停止使用本服务。
            </span>
          </p>
          <p>
            （三）您保证，在您同意接受本协议并注册成为本公司平台用户时，您已经具备相应的民事行为能力，或者您是在中国大陆地区依法设立并合法开展经营活动或其他业务的法人或其他组织；本协议内容不受您所属国家或地区法律的排斥。在您注册成为本公司平台用户时或在此后任何时间不具备前述条件的，您应立即终止注册或停止使用本服务。您在使用本公司平台服务时，应自行判断对方是否是完全民事行为能力人，且确定他提供的信息和他本人对应，并自行决定是否与对方进行签约交易等，且您应自行承担与此相关的所有风险。本平台不承担因对方身份确认失误而造成的损失。
          </p>
          <p>
            <span class="bold">
              （四）您保证，在您同意接受本协议并注册成为本公司平台用户时，即表示您认可使用本公司平台作为缔约（包括但不限于电子合同）方式，认可您在本公司平台上的所有操作都是您个人意愿的表示，认可您在本公司平台上签订的电子合同（或协议）和纸质合同（或协议）具备相同的效力，认可您在本公司平台上的电子签名和您的手写签名具备相同的效力。
            </span>
          </p>
          <p>
            （五）您同意，就您使用本公司平台服务，本公司有权将您的身份信息及签约合同信息相应传输至电子认证服务机构（CA,
            Certificate
            Authority）或公证处，由CA以出具数字证书之目的或公证处以出具公证文件之目的使用并保存，且前述信息可以保存至数字证书失效后或相关电子数据（包括但不限于签约合同电子数据）产生后5年，并且该等信息不因您终止本公司平台服务而停止保存。
          </p>
          <p>
            （六）您同意，就您使用本公司平台服务，本公司有权将您的身份信息、证件复印件或扫描件相应传输至征信机构及其他服务提供商，由该等机构以验证签约方身份之目的使用并保存。
          </p>
          <p>
            （七）您确认并知悉，本公司平台服务不适用于以下数据电文等法律文件的签订：
            <br />
            1、涉及婚姻、收养、继承等人身关系的；
            <br />
            2、涉及停止供水、供热、供气等公用事业服务的；
            <br />
            3、法律、行政法规规定的不适用电子文书的其他情形。
          </p>
          <p class="bold">二、定义及解释</p>
          <p>
            <span class="bold">（一）本公司平台账户（或“该账户”）：</span>
            是本公司平台向您提供的唯一编号。您可自行为本公司平台账户设置密码，并用以查询或发起代表你个人、公司或者组织的签约过程。您需使用本人电子邮箱或手机号码，或者本公司允许的其它方式，例如通过扫描二维码、识别生物特征的方式，作为登录手段登录本公司平台账户。
          </p>
          <p>
            <span class="bold">（二）数据源：</span>
            指合法持有您的身份信息并依法向本平台及相关供应商提供实名认证比对结果的主体，包括：（1）依法运营政府部门数据库的主体，包括各级行政机关、政府部门、公共管理部门、司法机关、公共信用信息管理部门等主体；（2）依法运营商业化数据库的主体，包括电信运营商、金融机构等企事业单位。具体以实际合作情况为准。
          </p>
          <p>
            <span class="bold">（三）身份信息：</span>
            指您的姓名、国籍、性别、学历学籍、职业、住址（注册地址）、联系方式、营业执照、法定代表人、注册资本、经营期限、统一社会信用代码、经营范围以及您有效证件的种类、号码和有效期限等能够依法识别您身份的信息。
          </p>
          <p>
            <span class="bold">（四）您：</span>
            指注册获得本公司平台账号并使用本公司平台各项服务的自然人、法人或其他组织。
          </p>
          <p class="bold">三、平台服务</p>
          <p>
            <span class="bold">（一）本公司平台服务（以下简称为本服务）：</span>
            指本条（二）所列的服务，及您实际使用的本公司或本公司接受您的委托为您不时提供的服务以及提供的其他服务。
          </p>
          <p>
            <span class="bold">
              （二）本公司平台服务包括（但不限于）以下服务：
            </span>
          </p>
          <p>1、电子协议服务</p>
          <p>
            通过本公司平台，本公司向注册用户提供在平台上的电子协议发送，签署，接受，打印等服务。
          </p>
          <p>2、电子证据保存和公证服务</p>
          <p>
            本公司通过指定合作方公司提供所有在“本公司平台上的电子交易证据保存服务和公证服务。若用户需要对交易过程中产生的协议进行公证，可以通过本平台提出申请，并和指定合作公司和公证机构签订协议（若需要），并支付指定公司及公证机构相关的服务费用。
          </p>
          <p>
            3、对于通过本公司平台签订的数据电文等法律文件的，本公司有权将前述法律文件的签署信息如当事人姓名或者名称、统一社会信用代码证（或者组织机构代码证）等号码、身份证号码、数字证书号码、IP地址、签订时间等披露给数据电文的签约当事人作为法律文件签订证据。
          </p>
          <p class="bold">四、平台账户</p>
          <p class="bold">（一）注册相关</p>
          <p>
            除本协议另有规定或相关产品另有规则外，您须在本平台网站及/或无线客户端注册并取得本公司提供给您的平台账户，并且按照本公司要求提供相关信息完成激活后方可使用本服务。您同意：
          </p>
          <p class="bold">
            1、按照本公司要求准确提供并在取得该账户后及时更新您正确、最新及完整的身份信息及相关资料。若本公司有合理理由怀疑您提供的身份信息及相关资料错误、不实、过时或不完整的，本公司有权暂停或终止向您提供部分或全部平台服务。本公司对此不承担任何责任，您将承担因此产生的任何直接或间接支出。若因国家法律法规、部门规章或监管机构的要求，本公司需要您补充提供任何相关资料时，如您不能及时配合提供，本公司有权暂停或终止向您提供部分或全部平台服务。
          </p>
          <p>
            2、您应当准确提供并及时更新您提供的电子邮件地址、联系电话、联系地址、邮政编码等联系方式，以便本公司与您进行及时、有效联系。您应完全独自承担因通过这些联系方式无法与您取得联系而导致的您在使用本服务过程中遭受的任何损失或增加任何费用等不利后果。您理解并同意，您有义务保持您提供的联系方式的有效性，如有变更需要更新的，您应按本公司的要求进行操作。
          </p>
          <p class="bold">
            3、您应及时更新资料（包括但不限于身份证、户口本、护照、营业执照等证件或其他身份证明文件、联系方式、作为本公司平台登录名的邮箱或手机号码、与平台账户绑定的邮箱、手机号码等），否则本公司有权将平台登录名、账户绑定的邮箱、手机号码开放给其他用户注册或使用。因您未及时更新资料导致的一切后果，均应由您自行承担，该后果包括但不限于导致本服务无法提供或提供时发生任何错误、账户及账户内信息被别人盗用，且您不得将此作为取消交易、拒绝执行协议的理由。
          </p>
          <p>
            4、您确认，只有您本人可以使用您的平台账户。在您决定不再使用该账户时，您应按照本条（四）的规定向本公司申请注销该账户。您同意，若您丧失全部或部分民事权利能力或民事行为能力，本公司有权根据有效法律文书（包括但不限于生效的法院判决、生效的遗嘱等）或本公司认可的其他资料处置您平台账户内的资料。
          </p>
          <p class="bold">
            5、您确认，本公司有权在必要时要求核对您的有效身份证件或其他必要文件，并留存有效身份证件的彩色扫描件，您应积极配合，否则本公司有权限制或停止向您提供部分或全部平台服务：
          </p>
          <p>A、您要求变更身份信息或您身份信息已过有效期的；</p>
          <p>B、本公司认为您的交易行为或交易情况出现异常的；</p>
          <p>
            C、本公司认为您的身份资料存在疑点或本公司在向您提供服务的过程中认为您已经提供的身份资料存在疑点的；
          </p>
          <p>D、本公司认为应核对或留存您身份证件或其他必要文件的其他情形的。</p>
          <p class="bold">
            6、您在本公司平台进行注册时，可以选择是否愿意接受平台的电话回访，若您不同意电话回访可选择不勾选，本公司将不会对您进行电话回访。
          </p>
          <p class="bold">（二）账户安全</p>
          <p>
            您将对使用该账户或密码进行的一切操作及言论负完全的责任，您同意：
          </p>
          <p class="bold">
            1、除相关产品另有规则外，本公司可以通过您的平台登录名和密码或扫描二维码或识别您的生物特征或者本公司认可的其他方式识别您的身份，您应当对该密码、身份信息等进行妥善保管，对于因密码、身份信息、校验码等泄露所致的损失由您自行承担。您保证不向其他任何人泄露您的平台登录名及密码、校验码以及身份信息等，亦不使用其他任何人的平台登录名及密码。本公司亦可能通过本服务应用您使用的其他产品或设备识别您的指示，您应当妥善保管处于您或应当处于您掌控下的这些产品或设备，对于这些产品或设备遗失所致的任何损失，由您自行承担。
          </p>
          <p>
            2、基于计算机端、手机端以及使用其他电子设备的用户使用习惯，本公司可能在您使用具体产品时设置不同的账户登录模式及采取不同的措施识别您的身份。
          </p>
          <p>
            3、您同意，（a）如您发现有他人冒用或盗用您的平台登录名及密码或任何其他未经合法授权之情形，或发生与平台账户关联的手机或其他设备遗失或其他可能危及到平台账户资金安全情形时，应立即以有效方式通知本公司，向本公司申请暂停相关服务，以保障您的合法权益；及（b）确保您在持续登录平台网站时段结束时，以正确步骤离开网站。
            <span class="bold">
              本公司不能也不会对因您未能遵守本款约定而发生的任何损失、损毁及其他不利后果负责。您理解本公司对您的请求采取行动需要合理期限，在此之前，本公司对已执行的指令及（或）所导致的您的损失不承担任何责任。
            </span>
          </p>
          <p class="bold">
            4、除非您本人书面提出申请或者另有法律规定或经司法裁判，且征得本公司同意，否则您的平台登录名及密码、平台账户不得以任何方式转让、赠与或继承（相关的财产权益除外）。
          </p>
          <p class="bold">
            5、您使用本服务时同意并认可，可能系统问题造成本服务无法提供，对此本公司不承担任何责任。
          </p>
          <p>
            6、您同意，基于运行和交易安全的需要，本公司可以暂时停止提供或者限制本服务部分功能，或提供新的功能，在任何功能减少、增加或者变化时，只要您仍然使用本服务，表示您仍然同意本协议或者变更后的协议。
          </p>
          <p>
            7、本公司有权了解您使用本公司产品或服务的真实交易背景及目的，您应如实提供本公司所需的真实、全面、准确的信息。
            <span class="bold">
              如果本公司有合理理由怀疑您提供虚假交易信息的，本公司有权暂时或永久限制您所使用的产品或服务的部分或全部功能，并通过邮件或者站内信或客户端通知等方式通知您，您应及时予以关注。
            </span>
          </p>
          <p>
            8、您同意，本公司有权按照包括但不限于公安机关、检察机关、法院、海关、税务机关等司法机关、行政机关、军事机关的要求对您的个人信息及在本公司平台的交易及账户等进行查询、冻结或其他操作。
          </p>
          <p class="bold">（三）注销相关</p>
          <p>在需要终止使用本服务时，您可以申请注销您的平台账户，您同意：</p>
          <p>
            1、您所申请注销的账户应当是您依照本协议的约定注册并由本公司提供给您本人的账户。您应当依照本公司规定的程序进行平台账户注销。
          </p>
          <p>
            2、本公司平台账户注销将导致本公司终止为您提供本服务，本协议约定的双方的权利义务终止（依本协议其他条款另行约定不得终止的或依其性质不能终止的除外），同时还可能对于该账户产生如下结果：
          </p>
          <p>A、任何您的交易信息都将无法查看；</p>
          <p>
            B、任何您的交易过程中产生的附属文档都将无法查看，您可以在注销之前进行保存。
          </p>
          <p>
            C、注销完成后，本公司无法对您之前留存下来的任何信息提供鉴权，公证等服务
          </p>
          <p>3、您可以通过自助或者人工的方式申请注销本公司平台账户。</p>
          <p>
            4、您申请注销的本公司平台账户应当处于正常状态，即您的账户信息和用户信息是最新、完整、正确的，且该账户可以使用所有平台服务功能。账户信息或用户信息过时、缺失、不正确的账户或被暂停或终止提供服务的账户不能被申请注销。如您申请注销的账户有关联账户或子账户的，在该关联账户或子账户被注销前，该账户不得被注销。
          </p>
          <p>
            5、您申请注销的本公司平台账户应当不存在任何由于该账户被注销而导致的未了结的合同关系与其他基于该账户的存在而产生或维持的权利义务，及本公司认为注销该账户会由此产生未了结的权利义务而产生纠纷的情况。
          </p>
          <p>6、如果您申请注销的本公司平台账户一旦注销成功，将不再予以恢复。</p>
          <p class="bold">
            7、您理解并同意，如（a）您连续12个月未使用您的平台登录名；或（b）您在本公司平台网站有欺诈、虚假交易、侵犯他人合法权益或其他严重违反平台网站规则或者中华人民共和国法律的行为的，本公司有权注销您名下的全部或部分平台登录名，您将不能再登录本公司平台网站，所有网站的服务将同时终止。
          </p>
          <p class="bold">五、平台服务使用规则</p>
          <p>为有效保障您使用本服务时的合法权益，您理解并同意接受以下规则：</p>
          <p>
            （一）一旦您使用本服务，您即不可撤销地授权本公司在您及（或）您指定人符合指定条件或状态时，向您的合作伙伴发送签约邀请并执行对应的操作。
          </p>
          <p>
            <span class="bold">
              （二）本公司通过以下方式接受来自您的指令：A、您在
            </span>
            本平台网站或其他可使用本服务的网站或软件上通过以您的平台账户名及密码或数字证书等安全产品登录本公司平台账户并依照本服务预设流程所修改或确认的交易状态或指令;
            <span class="bold">B、您通过</span>
            您注册时作为该账户名称或者与该账户绑定的手机或其他专属于您的通讯工具（以下合称该手机）号码向本公司发送的信息（短信或电话等）回复；
            <span class="bold">C、您通过</span>
            您注册时作为该账户名称或者与该账户名称绑定的其他硬件、终端、软件、代号、编码、代码、其他账户名等有形体或无形体向本公司发送的信息（如本方式所指有形体或无形体具备与该手机接受信息相同或类似的功能，以下第五条第（四）、（五）、（六）项和第六条第（三）项涉及该手机的条款同样适用于本方式）；
            <span class="bold">D、通过</span>
            识别您的指纹等生物特征；E、本公司与您约定或本公司认可的其他方式。
            <span class="bold">
              无论您通过以上何种方式向本公司发出指令，都不可撤回或撤销，且成为本公司代理您支付或收取款项或进行其他操作的唯一指令，视为您本人的指令，您应当自己对本公司忠实执行上述指令产生的任何结果承担责任。
            </span>
            本协议所称绑定，指您的平台账户与本条上述所称有形体或无形体存在对应的关联关系，这种关联关系使得本公司平台的某些服务功能得以实现，且这种关联关系有时使得这些有形体或无形体能够作为本公司对您的平台账户的识别和认定依据。
          </p>
          <p>
            （三）您确认，您在本平台网站上的任何操作以及因为操作导致的结果都具备法律效力。
            本服务即表示您同意接受本服务的相关规则。您了解并同意本公司有权单方修改服务的相关规则，而无须征得您的同意，服务规则应以您使用服务时的页面提示（或发送到该手机的短信或电话或客户端通知等）为准，您同意并遵照服务规则是您使用本服务的前提。
          </p>
          <p class="bold">
            （四）本公司会以站内状态通知（或发送到该手机的短信或电话等或客户端通知等）方式通知您交易进展情况以及提示您进行下一步的操作，但本公司不保证您能够收到或者及时收到该等通知，且不对此承担任何后果，因此，您应当及时查看该等通知并进行相关操作。因您没有及时查看和对交易状态进行修改或确认或未能提交相关申请而导致的任何纠纷或损失，本公司不负任何责任。
          </p>
          <p>
            （五）本公司对您所交易的协议内容不提供任何形式的鉴定服务。除本协议另有规定外，如您与交易对方发生交易纠纷，您不可撤销地授权由本公司根据本协议及本平台网站上载明的各项规则进行处理。您为解决纠纷而支出的通讯费、文件复印费、鉴定费等均由您自行承担。因市场因素致使而使任何一方得益或者受到损失而产生的纠纷（《争议处理规则》另有约定的除外），本公司不予处理。
          </p>
          <p>
            （六）您应按照本公司的要求完善您的身份信息以最终达到实名，否则您可能会受到部分功能限制。
          </p>
          <p>
            （七）本公司会将您的交易过程及产生的资料，严格按照法律法规或有权机关的监管要求进行管理；除本协议另有规定外，不作任何其他非您指示的用途。就您使用本公司平台服务，本公司有权将您的身份信息及签约合同信息相应传输至电子认证服务机构（CA,
            Certificate
            Authority）或公证处，由CA以出具数字证书之目的或公证处以出具公证文件之目的使用并保存，且前述信息可以保存至数字证书失效后或相关电子数据（包括但不限于签约合同电子数据）产生后5年，并且该等信息不因您终止本公司平台服务而停止保存。同时，本公司有权将您的身份信息、证件复印件或扫描件相应传输至征信机构及其他具有国家认可资质的数据服务提供商，由该等机构以验证签约方身份之目的使用并保存。您和任何签约方知悉并保证：本公司是数据电文等法律文件的签订平台，而不是CA机构或者实名认证机构。因此，您或者任何签约方通过本平台协助您和任何签约方开展实名认证的，本公司不对实名结果承担法律责任。因实名和意愿性校验无效而导致签订的数据电文等法律文件被认定无效的，相关的法律责任概与本公司无关。但因本公司故意造成无效的除外。
          </p>
          <p>
            （八）本公司并非法律机构，因交易内容或者因为协议内容产生的纠纷，本公司不承担任何解决义务，您可以向符合中华人民共和国法律规定的相关单位寻求解决。
          </p>
          <p>（九）您不得将本服务用于非本公司许可的其他用途。</p>
          <p>（十）签约风险</p>
          <p class="bold">
            1、在使用本服务时，若您或您的签约对方未遵从本服务条款或平台网站说明、交易页面中之操作提示、规则，则本公司有权拒绝为您与签约对方提供相关服务，且本公司不承担损害赔偿责任。
          </p>
          <p class="bold">
            2、因您的过错导致的任何损失由您自行承担，该过错包括但不限于：不按照提示操作，未及时进行操作，遗忘或泄漏密码、校验码等，密码被他人破解，您使用的计算机或其他硬件、终端等被他人侵入或丢失，或您使用的软件被他人侵入，或者您的生物特征被他人利用。
          </p>
          <p class="bold">
            3、当您和数据电文的签约方在签订数据电文等法律文件时，应当自行判定自己及其他当事人的主体资格是否适格。如果您和/或者其他当事人是无民事行为能力人或者限制民事行为能力人，不得签订或者仅能签订与自己的年龄、智力和精神健康状况相适应的法律文件。因主体不适格导致通过本公司平台平台签订的数据电文等法律文件无效的，责任和法律后果由您和该法律文件其他签约方自己承担。
          </p>
          <p>
            （十一）您认可本公司平台账户的使用记录、交易状态等数据等均以本公司系统记录的数据为准。如您对该等数据存有异议的，应自您账户数据发生变动之日起三日内向本公司提出异议，并提供相关证据供本公司核实。
          </p>
          <p class="bold">六、平台服务使用限制</p>
          <p>
            （一）您在使用本服务时应遵守中华人民共和国相关法律法规、您所在国家或地区之法令及相关国际惯例，不将本服务用于任何非法目的（包括用于禁止或限制交易物品的交易），也不以任何非法方式使用本服务。
          </p>
          <p>
            （二）您不得利用本服务从事侵害他人合法权益之行为，否则本公司有权拒绝提供本服务，且您应承担所有相关法律责任，因此导致本公司或本公司雇员或其他方受损的，您应承担赔偿责任。上述行为包括但不限于：
          </p>
          <p>
            1、侵害他人名誉权、隐私权、商业秘密、商标权、著作权、专利权等合法权益。
          </p>
          <p>2、违反依法定或约定之保密义务。</p>
          <p>3、冒用他人名义使用本服务。</p>
          <p>
            4、从事不法交易行为，如洗钱、恐怖融资、贩卖枪支、毒品、禁药、盗版软件、黄色淫秽物品、其他本公司认为不得使用本服务进行交易的物品等。
          </p>
          <p>5、提供赌博资讯或以任何方式引诱他人参与赌博。</p>
          <p>6、非法使用他人账户交易。</p>
          <p>7、进行与您或交易对方宣称的交易内容不符的交易，或不真实的交易。</p>
          <p>8、从事任何可能含有电脑病毒或是可能侵害本服务系统、资料之行为。</p>
          <p>9、其他本公司有正当理由认为不适当之行为。</p>
          <p class="bold">
            （三）您理解并同意，本公司不对因下述任一情况导致的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其他无形损失的损害赔偿（无论本公司是否已被告知该等损害赔偿的可能性）：
          </p>
          <p>
            1、本公司有权基于单方判断，包含但不限于本公司认为您已经违反本协议的明文规定及精神，对您名下的全部或部分平台账户暂停、中断或终止向您提供本服务或其任何部分，并移除您的资料。
          </p>
          <p>
            2、本公司在发现异常交易或合理怀疑交易有疑义或有违反法律规定或本协议约定之虞时，有权不经通知先行暂停或终止您名下全部或部分平台账户的使用（包括但不限于对这些账户名下的在途交易采取取消交易等限制措施），并拒绝您使用本服务之部分或全部功能，并通过邮件或站内信或客户端通知等方式通知您，您应及时予以关注。
          </p>
          <p>
            3、您理解并同意，存在如下情形时，本公司有权对您名下平台账户暂停或终止提供全部或部分平台服务，且有权限制您所使用的产品或服务的部分或全部功能（包括但不限于对这些账户名下在途交易采取取消交易等限制措施），并通过邮件或站内信或者客户端通知等方式通知您，您应及时予以关注：
          </p>
          <p>1）根据本协议的约定：</p>
          <p>2）根据法律法规及法律文书的规定：</p>
          <p>3）根据有权机关的要求：</p>
          <p>4）您使用本公司平台服务的行为涉嫌违反国家法律法规及行政规定的：</p>
          <p>5）本公司基于单方面合理判断认为账户操作、资金进出等存在异常时：</p>
          <p>6）本公司依据自行合理判断认为可能产生风险的：</p>
          <p>
            7）您在参加市场活动时有批量注册账户、刷信用及其他舞弊等违反活动规则、违反诚实信用原则的：
          </p>
          <p>8）您遭到他人投诉，且对方已经提供了一定证据的：</p>
          <p>9）您可能错误地将他人账户进行了实名制或实名认证的。</p>
          <p>
            如您申请恢复服务、解除上述止付或限制，您应按本公司要求如实提供相关资料及您的身份证明以及本公司要求的其他信息或文件，以便本公司进行核实，且本公司有权依照自行判断来决定是否同意您的申请。您应充分理解您的申请并不必然被允许。您拒绝如实提供相关资料及身份证明的，或未通过本公司审核的，则您确认本公司有权长期对该等账户停止提供服务且长期限制该等产品或者服务的部分或全部功能。
          </p>
          <p>
            4、在本公司认为该等异常已经得到合理解释或有效证据支持或未违反国家相关法律法规及部门规章的情况下，最晚将于得到解释之日起的30个日历天内解除限制。但本公司有进一步理由相信该等异常仍可能对您或其他用户或本公司造成损失的情形除外，包括但不限于：
          </p>
          <p>1）收到针对该等异常的投诉：</p>
          <p>
            2）您已经实质性违反了本协议或另行签署的协议，且本公司基于保护各方利益的需要必须继续止付款项或暂停执行指令：
          </p>
          <p>
            3）您虽未违反国家相关法律法规及部门规章规定，但该等使用涉及本公司限制合作的行业类目或商品，包括但不限于通过本公司的产品或服务从事类似金字塔或矩阵型的高额返利业务模式。
          </p>
          <p>
            5、在本公司合理认为有必要时，本公司无需事先通知即可终止提供本服务，并暂停、关闭或删除您名下全部或部分平台账户及这些账户中所有相关资料及档案。
          </p>
          <p>
            （四）如您需要注销本公司平台账户，应先经本公司审核同意。本公司注销该账户，即表明本公司与您之间的协议已终止，但您仍应对您使用本服务期间的行为承担可能的违约或损害赔偿责任，同时本公司仍可保有您的相关信息。
          </p>
          <p class="bold">七、用户信息及隐私权保护</p>
          <p>
            （一）您同意并授权本公司在您使用平台服务的范围内为验证签约主体身份信息之目的自行或通过其他具有法律要求的资质的机构、行业协会自公开及合法的数据来源收集您的额外资料和信息，收集的资料和信息范围包括：
          </p>
          <p>
            1、您的身份信息，包括但不限于姓名/名称、证件号码、证件类型、住所地、营业执照、法定代表人、注册资本、经营期限、统一社会信用代码、经营范围、电话号码以及其他身份信息；
          </p>
          <p>
            2、您在公司的关联方和合作伙伴中留存以及形成的任何数据和信息，包括但不限于您的证券账户和/或银行账户信息等；您在公司、公司的关联方或公司合作伙伴以及其他机构的网络平台留存、形成、产生的有关法律文件以及任何信息或行为数据，包括但不限于您的交易信息、金融理财产品账户信息、投融资产品账户信息、投融资项目信息、履约或违法行为信息等；
          </p>
          <p>
            3、您的信用信息，包括但不限于您的征信记录和信用报告及其他信用信息；
          </p>
          <p>
            4、您的财产信息，包括但不限于您的店铺/企业经营状况、财税信息、房产信息、车辆信息、基金、保险、股票、信托、债券等投资理财信息和负债信息等；
          </p>
          <p>
            5、您在行政机关、司法机关留存的任何信息，包括但不限于户籍信息、工商信息、诉讼信息、执行信息和违法犯罪信息等；
          </p>
          <p>
            6、与您申请或使用的平台服务相关的、您留存在其他自然人、法人和组织的其他相关信息。
          </p>
          <p>
            （二）本公司重视对用户隐私的保护。关于您的身份资料和其他特定资料受到保护与规范。就您使用本公司平台服务，本公司有权将您的身份信息及签约合同信息相应传输至电子认证服务机构（CA,
            Certificate
            Authority）或公证处，由CA以出具数字证书之目的或公证处以出具公证文件之目的使用并保存，且前述信息可以保存至数字证书失效后或相关电子数据（包括但不限于签约合同电子数据）产生后5年，并且该等信息不因您终止本公司平台服务而停止保存。同时，本公司有权将您的身份信息、证件复印件或扫描件相应传输至征信机构及其他具有国家认可资质的数据服务提供商，由该等机构以验证签约方身份之目的使用并保存。
          </p>
          <p class="bold">八、系统中断或故障</p>
          <p class="bold">
            本公司系统因下列状况无法正常运作，使您无法使用各项服务时，本公司不承担损害赔偿责任，该状况包括但不限于：
          </p>
          <p class="bold">（一）本公司在本平台网站公告之系统停机维护期间。</p>
          <p class="bold">（二）电信设备出现故障不能进行数据传输的。</p>
          <p class="bold">
            （三）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本公司系统障碍不能执行业务的。
          </p>
          <p class="bold">
            （四）由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。
          </p>
          <p class="bold">九、责任范围及责任限制</p>
          <p>（一）本公司仅对本协议中列明的责任承担范围负责。</p>
          <p>
            （二）您明确因在本平台的交易所产生的任何风险应由您与交易对方承担。
          </p>
          <p>
            （三）本公司用户信息是由用户本人自行提供的，本公司无法保证该信息之准确、及时和完整，您应对您的判断承担全部责任。
          </p>
          <p>
            （四）本公司不对交易标的及本服务提供任何形式的保证，包括但不限于以下事项:
          </p>
          <p>1、本服务符合您的需求。</p>
          <p>2、本服务不受干扰、及时提供或免于出错。</p>
          <p>
            3、您经由本服务购买或取得之任何产品、服务、资讯或其他资料符合您的期望。
          </p>
          <p>
            （五）本服务之合作单位，所提供之服务品质及内容由该合作单位自行负责。
          </p>
          <p>
            （六）您经由本服务之使用下载或取得任何资料，应由您自行考量且自负风险，因资料之下载而导致您电脑系统之任何损坏或资料流失，您应负完全责任。
          </p>
          <p>
            （七）您自本公司及本公司工作人员或经由本服务取得之建议和资讯，无论其为书面或口头形式，均不构成本公司对本服务之保证。
          </p>
          <p>
            （八）在法律允许的情况下，本公司对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、商誉损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。另外即使本协议规定的排他性救济没有达到其基本目的，也应排除本公司对上述损失的责任。
          </p>
          <p class="bold">
            （九）除本协议另有规定外，在任何情况下，本公司对本协议所承担的违约赔偿责任总额不超过向您收取的当次服务费用总额。
          </p>
          <p>
            （十）您充分知晓并同意本公司可能同时为您及您的（交易）对手方提供本服务，您同意对本公司可能存在的该等行为予以明确豁免，并不得以此来主张本公司在提供本服务时存在法律上的瑕疵。
          </p>
          <p>
            （十一）除本协议另有规定或本公司另行同意外，您对本公司的委托及向本公司发出的指令均不可撤销。
          </p>
          <p class="bold">十、商标、知识产权、专利的保护</p>
          <p>
            （一）本公司及关联公司所有系统及本平台网站上所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由本公司或本公司关联公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
          </p>
          <p>
            （二）非经本公司或本公司关联企业书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本平台网站的程序或内容。
          </p>
          <p>
            （三）尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任。
          </p>
          <p class="bold">十一、法律适用与管辖</p>
          <p class="bold">
            本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。因本协议产生之争议，均应依照中华人民共和国法律予以处理，并由被告住所地人民法院管辖。
          </p>

          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">
              确 定
            </el-button>
          </span>
        </el-dialog>

        <!-- 隐私政策弹窗 -->
        <el-dialog
          title="隐私政策"
          :visible.sync="dialogVisible1"
          center
          :fullscreen="true"
          width="30%"
          :before-close="handleClose1"
        >
          <p>
            <span class="bold">北京联想科技服务有限公司</span>
            (以下或称为“我们”或“本公司平台”)非常重视对您的个人隐私保护，我们将按照本隐私政策(以下称“本政策”)收集、使用、共享和保护您的个人信息。
          </p>
          <p>
            我们收集、使用、共享和保护您的个人信息，是在遵守国家法律法规规定的前提下，出于向您提供本公司平台的产品及服务并不断提升产品及服务质量的目的，以及保障您的账户安全。
          </p>
          <p>请在使用我们的产品(或服务)前，仔细阅读并了解本《隐私政策》。</p>
          <p class="bold">一、我们如何收集和使用您的个人信息</p>
          <p>
            我们会出于本政策所述的以下目的，收集和使用您的
            <span class="bold">个人信息:</span>
          </p>
          <p class="bold">（一）罗列的使用场景如下：</p>
          <p>1、注册</p>
          <p>
            当您注册本公司平台时，我们需要您提供
            <span class="bold">真实姓名、常用手机号码或者电子邮箱地址，</span>
            并创建
            <span class="bold">登录密码</span>
            和
            <span class="bold">签约密码</span>
            。在需要短信验证码的环节，可能需要读取验证码短信内容，以协助您进行验证码填写。如果您仅需使用浏览基本服务，您不需要注册成为我们的会员及提供上述信息。
          </p>
          <p>2、登录</p>
          <p>
            当您登录本公司平台时，我们需要您输入手机号码或者电子邮箱地址，作为登录账号。
          </p>
          <p>3、实名认证</p>
          <p>
            当您进行实名认证时，我们还会收集您的身份证/护照身份证明或面部生物特征信息;手机号码、身份证明或生物特征，这些信息属于敏感信息，收集此类信息是为了满足相关法律法规的实名制要求。若您不提供这类信息，您将无法正常使用如发起合同、申请公证、享受本公司平台的会员服务，但不会影响使用本公司平台产品或服务的基本浏览功能。
          </p>
          <p>4、签名</p>
          <p>
            当您设置签名时，我们会申请
            <span class="bold">相册权限，</span>
            以获取您的签名图片作为合同签署完成后展示的个人签名样式，方便您签署合同使用。您可要求更改、删除该图片。
          </p>
          <p>5、安全（指纹、面容ID）</p>
          <p>
            当您使用平台时，可以设置指纹、面容ID登录。我们会获取您的
            <span class="bold">指纹信息/面容ID，</span>
            用于登录校验。您也可以不设置，选择密码或验证码方式进行登录。
          </p>
          <p>6、发送合同</p>
          <p>
            当您使用平台发送合同时，我们会申请获取您的
            <span class="bold">存储权限，</span>
            用于查看本地文件；可能申请获取
            <span class="bold">相机/相册权限，</span>
            用于上传合同文件；申请获取
            <span class="bold">通讯录权限，</span>
            用于填写手机方联系方式，便于您的合同发出。
          </p>
          <p>7、签署审批</p>
          <p>
            当您签署审批时，我们会使用您已经设置好的
            <span class="bold">签名图片</span>
            作为个人签名样式，展示在合同文件的相应位置。您可更改、删除该图片。但删除后，无法进行签署。
          </p>
          <p>8、搜索</p>
          <p>
            当您进行搜索时，我们可能会申请读取您
            <span class="bold">剪切板</span>
            的数据用于合同文件名称的搜索，方便您找到您需要的合同文件。
          </p>
          <p>9、扫一扫</p>
          <p>
            当您使用扫一扫功能，扫描二维码进行查看、签署合同时，我们会申请获取您的
            <span class="bold">相机权限。</span>
          </p>
          <p>10、问题反馈（建议与咨询）</p>
          <p>
            当您在“我的-建议与咨询”中向我们反馈意见时，可能需要您提供联系邮箱与联系电话，以便我们能够及时的给您回复信息。当您拨打我们的客服热线时，我们可能会向您申请电话权限，方便您一键拨打客服电话。
          </p>
          <p class="bold">（二）向您提供产品或服务</p>
          <p>
            当您使用本公司平台的服务时，我们会收集您的
            <span class="bold">
              设备名称（包括别名）、设备型号、操作系统、IP地址、MAC地址、设备软件版本信息、设备识别码、设备标识码、设备标识符、指纹信息、面容ID信息、信息所在地区、网络使用习惯以及其他与本公司平台服务相关的日志信息，
            </span>
            这类信息是为提供服务必须收集的基础信息，如您不同意我们记录前述信息，可能无法完成风控验证。
          </p>
          <p>
            请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
          </p>
          <p>
            1、摄像头权限及相册权限：当您使用扫一扫、头像图片设置、截图反馈等功能时，您需要开启摄像头权限和/或相册权限（以您使用的具体功能为准），以便您进行实时拍摄或图片/视频上传。如您选择不开启相应权限，您将无法使用与实时拍摄或图片/视频上传相关的特定功能，但不影响您使用本公司平台提供的其他服务。
          </p>
          <p>
            2、基于面容、指纹等生物特征识别的功能：当您使用面容、指纹ID登录或签署时，您需要授权使用的设备的面容、指纹ID验证功能，帮助您完成个人身份识别、登录、验证以及签署指令操作。当您进行面容、指纹ID等验证时，我们仅收集验证结果，并不收集您的生物特征信息；
          </p>
          <p>
            当您与我们联系时，我们可能会保存您的
            <span class="bold">通信/通话记录和内容</span>
            或您留下的
            <span class="bold">联系方式信息，</span>
            以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
          </p>
          <p>
            为保障您正常使用我们的服务，我们还会
            <span class="bold">申请开启存储权限，</span>
            以保存您的合同文件；
          </p>
          <p>
            <span class="bold">申请开启电话权限，获取电话状态，</span>
            以方便一键拨打客服电话；
          </p>
          <p>
            <span class="bold">申请开启相机权限，</span>
            以拍摄文件照片、印章照片；
          </p>
          <p>
            <span class="bold">申请开启通讯录权限，获取联系人信息，</span>
            以方便填写合同发送方；
          </p>
          <p>
            <span class="bold">申请开启位置权限，获取定位信息，</span>
            以便于获取更准确的身份验证信息，完成校验身份和风控。
          </p>
          <p class="bold">（三）为您提供安全保障</p>
          <p>
            请注意，为确保会员身份真实性、向您提供更好的安全保障，您可以向我们提供
            <span class="bold">
              身份证、护照以及其它可以识别的身份信息、面部特征生物识别信息
            </span>
            完成实名认证。如您拒绝提供上述信息，可能无法使用发起合同、申请公证服务，但不会影响您使用浏览服务。
          </p>
          <p class="bold">（四）其他用途</p>
          <p>
            我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
          </p>
          <p class="bold">（五）征得授权同意的例外</p>
          <p>
            根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意:
          </p>
          <p>1、与国家安全、国防安全有关的;</p>
          <p>2、与公共安全、公共卫生、重大公共利益有关的;</p>
          <p>3、与犯罪侦查、起诉、审判和判决执行有关的;</p>
          <p>
            4、出于维护个人信息主体或其他个人的生命、财产重大合法权益但又很难得到您本人同意的;
          </p>
          <p>5、所收集的个人信息是您自行向社会公众公开的;</p>
          <p>
            6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开渠道;
          </p>
          <p>7、根据您的要求签订合同所必需的;</p>
          <p>8、用于维护所提供的产品或服务的安全稳定运行所必需的;</p>
          <p>9、法律法规规定的其他情形。</p>
          <p>
            如我们停止运营本公司平台产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一告知或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
          </p>
          <p class="bold">二、我们如何使用 Cookie 和同类技术</p>
          <p>
            为确保网站正常运转、为您获得更轻松的访问体验，我们会在您的计算机或移动设备上存储名为
            Cookie 的小数据文件。Cookie
            通常包含标识符、站点名称以及一些号码和字符。我们不会在Cookie里面存储与个人身份或隐私相关的信息。
          </p>
          <p>
            您可根据自己的偏好管理或删除
            Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有
            Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
          </p>
          <p class="bold">三、我们如何共享、转让和公开披露您的个人信息</p>
          <p class="bold">（一）共享</p>
          <p>
            我们不会与本公司平台的服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外:
          </p>
          <p>
            1、在获取明确同意的情况下共享:获得您的明确同意后，我们会与其他方共享您的个人信息。
          </p>
          <p>
            2、在法定情形下的共享:我们可能会根据法律法规规定，或按行政、司法机关依法提出的强制性要求，对外共享您的个人信息。
          </p>
          <p>
            3、与授权合作伙伴共享:仅为实现本隐私政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们会根据法律法规与合作伙伴共享您的某些个人信息。您的个人身份信息会传输至电子认证服务机构(CA,
            Certificate
            Authority)或个人身份信息及签约合同信息传输至公证处，由CA以出具数字证书之目的或公证处以出具公证文件之目的使用并保存。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。
          </p>
          <p>
            4、通过SDK方式与第三方共享:某些产品或服务可能由第三方提供或由我们与第三方共同提供，因此只有共享您的信息，才能提供您需要的产品或服务。本公司平台=和电子签约云平台通过嵌入插件（如SDK）的形式向您提供产品或者服务，同时在此过程中，第三方服务提供者可能会收集、使用和存储你的相关数据或信息。
          </p>
          <p>第三方SDK收集个人信息详情如下：</p>
          <p>
            <img src="./image/ys.png" style="width: 1200px" />
          </p>
          <p>
            腾讯云刷脸SDK包括：WbCloudFaceLiveSdk、WbCloudOcrSdk、WbCloudNormal、com.webank.mbank、com.tencent.youtulivecheck，4个SDK组件其中，腾讯云SDK进行人脸核验身份采集权限的说明：
          </p>
          <p>
            <img src="./image/ys2.png" style="width: 1200px" />
          </p>
          <p>设备指纹清单：</p>
          <p>
            <img src="./image/ys3.png" style="width: 1200px" />
          </p>
          <p>其中，io.flutter.embedding、io.flutter.plugins权限的说明：</p>
          <p>
            <img src="./image/ys4.png" style="width: 1200px" />
          </p>
          <p>以上信息只有在征得用户同意后再获取。</p>
          <p>
            本公司平台通过与上述第三方服务提供者签署的有关协议、本公司平台相关规范以及合理的产品流程设计，严格要求第三方服务提供者获取你的任何数据均应遵守相关法律法规的规定，必须事先获得你的明确同意，采取必要的数据保护措施（例如将该类个人信息做匿名化、去标识化处理），且仅为产品或服务之目的合理使用你的相关数据，向你提供修改或删除自己数据的方式，并在停止服务时必须删除全部数据等，尽最大可能保障你的数据和隐私不受侵害。你在接受或使用第三方产品或服务前应充分了解第三方产品或服务的条款及政策，如果你发现有第三方服务提供者存有相关违法违规行为，可以向本公司平台投诉，我们将查实后予以处理。
          </p>
          <p class="bold">（二）转让</p>
          <p>
            我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外:
          </p>
          <p>
            1、在获取明确同意的情况下转让:获得您的明确同意后，我们会向其他方转让您的个人信息;
          </p>
          <p>
            2、在本公司平台服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
          </p>
          <p class="bold">（三）公开披露</p>
          <p>我们仅会在以下情况下，公开披露您的个人信息:</p>
          <p>
            1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息;
          </p>
          <p>
            2、在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。执法机关提出披露要求，我们会及时通知您，除非执法机关另有禁止披露的要求。
          </p>
          <p class="bold">
            （四）共享、转让、公开披露个人信息时事先征得授权同意的例外
          </p>
          <p>
            以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意:
          </p>
          <p>1、与国家安全、国防安全有关的;</p>
          <p>2、与公共安全、公共卫生、重大公共利益有关的;</p>
          <p>3、与犯罪侦查、起诉、审判和判决执行有关的;</p>
          <p>4、您自行向社会公众公开的个人信息;</p>
          <p>
            5、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开渠道。根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
          </p>
          <p class="bold">（五）提供证明</p>
          <p>
            对于通过本公司平台签订的数据电文等法律文件的，我们有权将前述法律文件的签署信息如当事人姓名或者名称、统一社会信用代码证（或者组织机构代码证）等号码、身份证号码、数字证书号码、IP地址、签订时间等披露给数据电文的签约当事人作为法律文件的签订证据。
          </p>
          <p class="bold">（六）您的个人信息储存期限以及超期的处理方式</p>
          <p>
            《电子签名法》第二十四条规定“电子认证服务提供者应当妥善保存与认证相关的信息，信息保存期限至少为电子签名认证证书失效后五年。因此，您的个人信息可在本公司平台免费储存五年，超过五年，您未作任何处理也不支付费用的，我们有权删除且不承担任何法律责任。
          </p>
          <p class="bold">四、我们如何保护您的个人信息</p>
          <p>
            （一）我们已采取符合业界标准的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。在您的浏览器与服务器之间交换数据时受
            SSL(Secure Socket Layer)协议加密保护;我们同时对本公司平台网站提供
            HTTPS(Hyper Text Transfer Protocol over Secure Socket
            Layer)协议安全浏览方式;我们会使用加密技术提高个人信息的安全性;我们会使用受信赖的保护机制防止个人信息遭到恶意攻击;我们会部署访问控制机制，确保只有授权人员才可访问个人信息;我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
          </p>
          <p>
            （二）我们已经取得以下认证:信息系统安全等级保护三级认证、ISO/IEC27001信息安全管理体系认证、ISO27018公共云PII保护管理体系认证、ISO38505-1数据治理认证、可信云认证和ISO22301业务连续性管理体系认证。
          </p>
          <p>
            （三）我们会采取合理可行的措施，避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
          </p>
          <p>
            （四）互联网并非绝对安全的环境，我们强烈建议您不要使用非本公司平台推荐的通信方式发送个人信息。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您及时联络我们，以便我们根据您的申请采取相应措施。
            请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
          </p>
          <p>
            （五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求及时向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。事件相关情况我们将以邮件、信函、电话或推送通知的方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
          </p>
          <p>
            （六）您通过本公司平台提交个人信息，代表您同意并授权给本公司平台收集、使用、共享和保护您的个人信息，若您因特殊原因申请撤回授权，请及时联系我们，以便于我们做相应处理。
          </p>
          <p class="bold">五、您的权利</p>
          <p>您可以通过以下方式访问及管理您的个人信息:</p>
          <p class="bold">（一）访问您的个人信息</p>
          <p>
            您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息:
            账户信息——如果您希望访问或编辑您的账户中的个人基本信息和更改您的密码、添加安全信息，您可以通过登录账号通过“用户中心”执行此类操作。
          </p>
          <p>
            如果您无法通过上述路径访问该个人信息，您可以随时通过本公司平台与我们取得联系。我们将在10个工作日内回复您的访问请求。
          </p>
          <p>
            对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“(六)响应您的上述请求”中的相关安排向您提供。
          </p>
          <p class="bold">（二）更正或补充您的个人信息</p>
          <p>
            当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过“（一）访问您的个人信息”中列明的方式提出更正或补充申请。
          </p>
          <p class="bold">（三）删除您的个人信息</p>
          <p>
            您可以通过“（一）访问您的个人信息”中列明的方式删除您的部分个人信息。
          </p>
          <p>在以下情形中，您可以向我们提出删除个人信息的请求:</p>
          <p>1、如果我们处理个人信息的行为违反法律法规;</p>
          <p>2、如果我们收集、使用您的个人信息，却未征得您的明确同意;</p>
          <p>3、如果我们处理个人信息的行为违反了与您的约定;</p>
          <p>4、如果您不再使用我们的产品或服务，或您主动注销了账号;</p>
          <p>5、如果我们不再为您提供产品或服务。</p>
          <p>
            若我们决定响应您的删除请求，我们还将同时通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。
          </p>
          <p>
            当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
          </p>
          <p class="bold">（四）注销账户</p>
          <p>
            在需要终止使用本公司平台产品或服务时，您可登录本公司平台进行申请注销本公司平台账户的操作。若您不方便上述操作，您还可以通过我们的电话（见本政策第九条）通过人工的方式申请注销本公司平台账户。
          </p>
          <p>
            在您主动注销账户之后，我们将停止为您提供产品或服务，根据《中华人民共和国民法典》或《中华人民共和国电子签名法》适用条款删除您的个人信息，或使其匿名化处理。
          </p>
          <p class="bold">（五）撤回已同意的授权</p>
          <p>
            在需要撤回系统对本公司平台的授权时，您可以进入系统设置取消对本公司平台的系统授权。
          </p>
          <p class="bold">（六）约束信息系统自动决策</p>
          <p>
            在某些业务功能中，我们可能仅依据信息系统、算法在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害本公司平台商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
          </p>
          <p class="bold">（七）响应您的上述请求</p>
          <p>
            为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
          </p>
          <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求:</p>
          <p>1、与国家安全、国防安全有关的;</p>
          <p>2、与公共安全、公共卫生、重大公共利益有关的;</p>
          <p>3、与犯罪侦查、起诉、审判和执行判决有关的;</p>
          <p>4、有充分证据表明您存在主观恶意或滥用权利的;</p>
          <p>
            5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的;
          </p>
          <p>6、涉及商业秘密的。</p>
          <p class="bold">（八）响应您的上述请求</p>
          <p>
            当您和数据电文的签约方在签订数据电文等法律文件时，应当自行判定自己及其他当事人的主体资格是否适格。如果您和/或者其他当事人是无民事行为能力人或者限制民事行为能力人，不得签订或者仅能签订与自己的年龄、智力和精神健康状况相适应的法律文件。因主体不适格导致通过本公司平台签订的数据电文等法律文件无效的，责任和法律后果由您和该法律文件其他签约方自己承担。
          </p>
          <p class="bold">六、我们如何处理儿童的个人信息</p>
          <p>
            我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。
            对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。
          </p>
          <p>
            尽管当地法律和习俗对儿童的定义不同，但我们将不满14周岁的任何人均视为儿童。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
          </p>
          <p class="bold">七、您的个人信息如何在全球范围转移</p>
          <p>
            我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外:
          </p>
          <p>1、法律法规有明确规定;</p>
          <p>2、获得您的明确授权。</p>
          <p>
            针对以上情形，我们会确保依据本隐私政策对您的个人信息提供在中华人民共和国境内足够同等的保护。
          </p>
          <p class="bold">八、本政策如何更新</p>
          <p>我们的隐私政策可能变更。</p>
          <p>未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。</p>
          <p>
            对于重大变更，我们还会提供更为显著的通知(包括我们会通过本公司平台网站公示的方式进行通知)。
          </p>
          <p>
            本政策在本公司平台的更新：我们会在每次内容更新后，通过本公司平台在【隐私政策】中显示红点提示，提醒您阅读，并在征得您的同意后，再为您提供服务。
          </p>
          <p>本政策所指的重大变更包括但不限于:</p>
          <p>
            1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式;
          </p>
          <p>2、我们在控制权方面发生重大变化。如并购重组引起的所有者变更;</p>
          <p>3、个人信息共享、转让或公开披露的主要对象发生变化;</p>
          <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化;</p>
          <p>
            5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时;
          </p>
          <p>6、个人信息安全影响评估报告表明存在高风险时。</p>
          <p>
            我们还会将本政策的旧版本存档，供您查阅。若您在本政策修订后继续使用本公司平台服务，这表示您已充分阅读、理解并接受修订后的本政策并愿意受修订后的本政策约束。
          </p>
          <p class="bold">九、侵权投诉、举报渠道：</p>
          <p>
            如您对本隐私政策有任何疑问、意见或建议，尤其是对个人信息保护方面有问题的，可以通过以下方式与我们联系：
          </p>
          <p>
            1、投诉、举报电话：
            <span class="bold">【15694788968】</span>
          </p>
          <p>
            2、投诉、举报邮箱：
            <span class="bold">【gongxy2@lenovo.com】</span>
          </p>
          <p>一般情况下，我们将在十五天内回复。</p>

          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible1 = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible1 = false">
              确 定
            </el-button>
          </span>
        </el-dialog>

        <!-- 数字证书使用协议 -->
        <el-dialog
          title="数字证书使用协议"
          :visible.sync="dialogVisible2"
          center
          :fullscreen="true"
          width="30%"
          :before-close="handleClose2"
        >
          <p class="bold">声明：</p>
          <p>
            1.您应当认真阅读并遵守《数字证书使用协议》（以下简称“本协议”），本协议具有合同效力，
            <span class="bold">
              当您勾选确认或点击确认并完成注册后即成立生效。
            </span>
          </p>
          <p>
            2.您务必审慎阅读、充分理解各条款内容，如您对本协议有任何疑问或对任何条款不能准确理解的，请不要进行后续操作。
          </p>
          <p class="bold">
            3.您承诺接受并遵守本协议的约定，届时您不可以未阅读本协议内容或未获得北京联想科技服务有限公司（以下称“本公司“）的平台（以下称“本公司平台”或“平台”）对您问询的解答等理由，主张本协议无效，或要求撤销本协议。
          </p>
          <p class="bold">
            4.您一旦进行数字证书的申请（包括续期、更新）、下载或使用，即表明同意接受并愿意遵守本协议的所有条款。
          </p>
          <p class="bold">一、数字证书服务简介</p>
          <p>
            1.您同意并认可本公司平台与工信部许可的电子认证服务机构合作，为通过实名认证后的互联网个人或企业申请数字证书。
          </p>
          <p>
            2.您同意并认可通过本公司电子签约平台完成实名认证并使用数字证书获得电子签名服务，成为本公司平台签名体系不可分割的部分。
          </p>
          <p>3.本协议中的“证书”指个人数字证书或企业数字证书。</p>
          <p class="bold">二、名词解释</p>
          <p>1.订户：</p>
          <p>
            指委托本公司平台从第三方电子认证服务机构获得证书的个人、法人或其他组织机构。
          </p>
          <p>2.数字证书：</p>
          <p>
            是指一段信息，它至少包含了一个名字，标识特定的CA或标识特定的订户，包含了订户的公钥、证书有效期、证书序列号，及CA数字签名。
          </p>
          <p>3.证书私钥：</p>
          <p>
            数字证书包含证书本身和一个密钥对，密钥对的一部分是公钥，另一部分称为私钥。公钥公之于众，谁都可以使用。私钥只有自己知道，一般信息都是由公钥进行加密，相对应的私钥进行解密。
          </p>
          <p class="bold">三、订户的权利和义务</p>
          <p>
            1.您应按照本公司规定的证书申请流程向平台提供有关资料，并保证所填写的注册信息和所提供的资料的真实性、准确性和完整性，否则本公司平台有权拒绝您的申请请求。
            <span class="bold">
              在这些信息、资料发生改变时您应当及时通知本公司平台。如因您的资料不真实、不完整、不准确或资料改变后未及时通知本公司平台，造成的损失由订户自己承担。
            </span>
          </p>
          <p>
            2.您的证书信息在证书有效期限内变更的，应当及时书面告知本公司平台，并立即终止使用该证书（注：企业名称变更、企业统一社会信用代码变更、经营期限届满、姓名变更、身份证号变更等需要吊销原证书，重新申请数字证书）。
          </p>
          <p>
            3.若您为企业用户，您企业因分立、合并、解散、注销、宣告破产或倒闭，或被吊销营业执照等导致主体资格终止的，应于上述情况发生前书面告知本公司平台申请吊销数字证书，并立即终止使用该证书，否则，因未尽该通知义务给本公司平台造成损失的，由您全部赔偿。
          </p>
          <p class="bold">
            4.您同意本公司平台向有关部门和个人核实您的信息。本公司平台应合法地收集、处理、传递及使用您的资料，并按照国家有关规定及本协议的约定予以保密。
          </p>
          <p>
            5.您对证书享有独立的使用权，
            您应当合法使用数字证书签署电子文件，并对使用数字证书的行为负责。您使用证书产生的权利，由您享有；您使用证书产生的义务、责任，由您承担。
          </p>
          <p class="bold">
            6.您应当对您所专有并控制的数字证书及本公司平台账户进行妥善保管，当您在本公司平台账户设置的通知方式（手机和邮箱）收到签署验证码时，应确保签署请求由本人发起，且对验证码承担保密义务。如您使用的数字证书私钥和密码泄漏、丢失，或者您不希望继续使用数字证书，或者您的主体不存在，您应当立即向本公司平台申请废止该数字证书。因您原因致使证书私钥泄露、损毁或者丢失的，损失由您承担。
          </p>
          <p>
            7.您损害第三方电子认证服务机构利益的，须向电子认证服务机构赔偿全部损失。这些情形包括但不限于：
          </p>
          <p>
            ①您在申请数字证书时没有提供真实、准确、完整信息，或在这些信息变更时未及时通知本公司平台；
          </p>
          <p>
            ②您知道自己的私钥已经失密或者可能已经失密而未及时告知有关各方、并终止使用；
          </p>
          <p>③您未履行本协议相关约定的其他情形。</p>
          <p>
            8.第三方电子认证服务机构有权因安全风险因素要求本公司平台更换您的数字证书。
          </p>
          <p class="bold">
            9.您申请数字证书后，一旦发现如下情况之一时，应当立即向本公司平台申请吊销此证书：
          </p>
          <p class="bold">
            ①证据表明，此数字证书被用于签署可疑代码，包括但不限于病毒，木马，或者其他不恰当的程序；
          </p>
          <p class="bold">②证书中内容不再正确或不再准确；</p>
          <p class="bold">
            ③此证书私钥信息已被泄露、丢失，或者其他相关部分已被错误使用。
          </p>
          <p class="bold">10.证书一旦被吊销，订户将不能再使用该证书。</p>
          <p class="bold">
            11.如果第三方电子认证服务机构发现了证书的不当使用，或者证书被用于违法甚至犯罪行为，电子认证服务机构有权直接吊销您的证书。
          </p>
          <p class="bold">
            12.在发现或怀疑第三方电子认证服务机构提供的认证服务造成订户的网上交易信息的泄漏和/或篡改时，您应在3个月内向电子认证服务机构提出争议处理请求并通知有关各方。
          </p>
          <p class="bold">
            13.根据《电子签名法》的规定，对于与您认证相关的信息以及您签署的电子合同等，您同意本公司平台有权自您的电子签名认证证书失效后保存十年。
          </p>
          <p class="bold">
            14.您同意并授权本公司平台将您的身份信息和您签署的电子合同提供给国家司法机关、行政机关，具有司法行政职能的事业单位和社会团体以及电子合同其他签订方。
          </p>
          <p class="bold">四、证书的申请、使用、更新、吊销</p>
          <p>
            1.
            您可以在本公司平台选择适合您的实名认证方式进行数字证书的申请，获得的证书和证书私钥后，由本公司平台托管，数字证书储存在本公司平台。证书仅用于本公司平台电子文件签署，您使用证书和证书密钥制作的电子签名制作数据专属于您所有并由您控制，仅在您同意的情况下，您的证书方可被调用签署相关的电子合同等法律文件。
          </p>
          <p>
            2.
            您每次在本公司平台签署电子文件都将使用您的数字证书。本公司平台只有核实了您的签署意愿后，才会依据您的意愿使用数字证书签署电子文件。
            <span class="bold">
              如果启用了指纹签署或自动盖章功能，视为您知情并同意每次签署电子文件的行为都使用您的数字证书。
            </span>
          </p>
          <p class="bold">
            3.
            本协议的有效期限为证书的有效期限，证书只能在数字证书有效期限内使用，证书有效期限届满，您需要继续使用的，应当及时办理证书更新手续，本协议有效期限顺延至证书更新期限届满日。在获得您将证书延期的授权前，本公司平台不会主动为您更新证书。
          </p>
          <p class="bold">
            4.
            证书私钥在证书有效期内损毁、丢失、泄露的，您应当及时申请办理吊销手续，吊销自手续办妥时起生效，吊销生效前发生的损失由您承担。
          </p>
          <p>
            5.
            您知悉证书私钥已经丢失或者可能已经丢失时，应当及时告知本公司平台。本公司平台将协助完成吊销该证书的工作。您应终止使用该证书。
          </p>
          <p class="bold">五、平台的权利</p>
          <p class="bold">
            您有下列情形之一，本公司平台有权向第三方机构申请吊销证书并不承担任何责任。由此给本公司平台造成损失的，您应当承担赔偿责任：
          </p>
          <p>1.您向本公司平台提供的资料或者信息不真实、不准确或者不完整的。</p>
          <p>2.您证书的信息有变更，未终止使用该证书并通知本公司平台的。</p>
          <p>
            3.您知悉证书私钥已经丢失或者可能已经丢失时，未终止使用该证书并通知本公司平台的。
          </p>
          <p>4.您超过证书的有效期限及应用范围使用证书的。</p>
          <p>
            5.您企业因分立、合并、解散、注销、宣告破产或倒闭，被吊销营业执照等导致主体资格终止而您未及时通知本公司平台吊销书证书的。
          </p>
          <p>6.您使用证书用于违法、犯罪活动的。</p>
          <p class="bold">
            六、第三方电子认证服务机构的服务、权利、义务、责任限制和免责
          </p>
          <p>
            1.
            第三方电子认证服务机构依法制定《电子认证业务规则》（简称CPS），并公布于第三方电子认证服务机构网站，明确第三方电子认证服务机构数字证书的功能、使用证书各方的权利、义务以及第三方电子认证服务机构的责任范围，本协议的相关条款源自CPS。包括但不限于：
          </p>
          <p>①《中国金融认证中心电子认证业务规则（CPS）》</p>
          <p>原文链接：http://www.cfca.com.cn/zhengshuzizhu/</p>
          <p>②《ZJCA电子认证业务规则》</p>
          <p>
            原文链接：https://www.zjca.com.cn/web/webs/service/download.html
          </p>
          <p>③《天威诚信电子认证业务规则》</p>
          <p>
            原文链接：https://www.itrus.com.cn/uploads/soft/200714/guize.pdf
          </p>
          <p>
            2.
            在订户通过安全工具使用数字证书对交易信息进行加密和签名的条件下，第三方电子认证服务机构将保证交易信息的保密性、完整性、抗抵赖性。如果发生纠纷，第三方电子认证服务机构将依据不同情形承担下述义务：
          </p>
          <p>①提供签发订户数字证书的CA证书；</p>
          <p>
            ②提供订户数字证书在交易发生时，在或不在第三方电子认证服务机构发布的数字证书废止列表内的证明；
          </p>
          <p>③对数字证书、数字签名、时间戳的真实性、有效性进行技术确认。</p>
          <p class="bold">
            3.有下列情形之一的，第三方电子认证服务机构有权吊销所签发的数字证书：
          </p>
          <p class="bold">
            ①订户申请数字证书时，提供的资料不真实、不准确、不完整；
          </p>
          <p class="bold">②订户未履行本协议约定的义务；</p>
          <p class="bold">③订户书面申请吊销数字证书；</p>
          <p class="bold">④证书的安全性不能得到保证；</p>
          <p class="bold">⑤法律、行政法规规定的其他情况。</p>
          <p class="bold">
            4.
            第三方电子认证服务机构将对订户申请数字证书时提交的信息进行审核，提供证书生命周期內的相关服务，同时向相关方提供查询服务。第三方电子认证服务机构及其注册机构均有义务保护订户隐私信息安全性。
          </p>
          <p class="bold">
            5.
            根据《电子签名法》的规定，如果订户依法使用第三方电子认证服务机构提供的认证服务进行民事活动而遭受损失的，第三方电子认证服务机构将给予相应赔偿，除非第三方电子认证服务机构能够证明其提供的服务是按照《电子签名法》等相关法律法规和第三方电子认证服务机构向主管部门备案的CPS实施的。以下损失不在赔偿之列：
          </p>
          <p class="bold">
            ①任何直接或间接的利润或收入损失、信誉或商誉损害、任何商机或契机损失、失去项目、以及失去或无法使用任何数据、无法使用任何设备、无法使用任何软件；
          </p>
          <p class="bold">②由上述损失相应生成或附带引起的损失或损害。</p>
          <p class="bold">6. 以下损失第三方电子认证服务机构将不承担责任：</p>
          <p class="bold">①非因第三方电子认证服务机构的行为而导致的损失；</p>
          <p class="bold">
            ②因不可抗力而导致的损失，如罢工、战争、灾害、电脑病毒、黑客攻击等。
          </p>
          <p class="bold">
            7.
            第三方电子认证服务机构对企业订户申请的数字证书的赔偿上限以各电子认证服务机构的官方说明为准。
          </p>
          <p class="bold">七、免责条款</p>
          <p class="bold">
            1.因设备故障、电力故障及通讯故障或者电脑病毒、自然灾害、黑客攻击等因素造成您损失的，本公司平台不承担任何责任。
          </p>
          <p class="bold">
            2.由于第三方电子认证服务机构的原因导致证书私钥被破译、窃取，致使您遭受损失的，由第三方电子认证服务机构向您承担赔偿责任。
          </p>
          <p class="bold">八、协议的终止</p>
          <p>有下列情形之一的，本协议终止：</p>
          <p>1.您证书期限届满。</p>
          <p>2.您证书被吊销。</p>
          <p>3.您向本公司平台申请终止本协议并获得同意的。</p>
          <p>4.双方协商终止本协议的。</p>
          <p>5.依据法律、法规等规定，本协议应当终止的。</p>
          <p class="bold">九、争议解决条款</p>
          <p class="bold">
            对于任何因履行本协议发生的或与本协议有关的一切争议，双方应首先通过友好协商的方式解决。不能协商解决的，各方均应依照中华人民共和国法律并由被告住所地的人民法院裁判。
          </p>

          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible2 = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible2 = false">
              确 定
            </el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { getPhoneCode, getLogin, getVerifyCode } from './api'
import { mapMutations } from 'vuex'

export default {
  data() {
    // 验证正确的密码格式: 6-16位，有数字和字母
    const passwordTest = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('密码不能为空'))
      } else {
        const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入6-18位并且必须包含数字和字母的密码'))
        }
      }
    }
    return {
      active: '1',
      show: true,
      count: 60,
      timer: null,
      formLabelWidth: '120px',
      checked: false,
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      verifyUrl: '',
      form: {
        name: '',
        password: '',
        pictureCode: '',
      },
      form2: {
        phone: '',
        code: '',
        pictureCode: '',
      },
      rules: {
        name: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
        pictureCode: [
          { required: true, message: '验证码不能为空', trigger: 'blur' },
        ],
        password: [
          { validator: passwordTest, required: true, trigger: 'blur' },
        ],
        phone: [
          {
            required: true,
            message: '手机号码或邮箱不能为空',
            trigger: 'blur',
          },
        ],
        code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this._getVerifyCode()
  },
  methods: {
    ...mapMutations(['setMenus', 'setToken', 'setUsername']),
    ljzc() {
      this.$router.push('/register')
    },
    onSubmit() {
      console.log('submit!')
    },

    handleClose() {
      this.dialogVisible = false
    },
    handleClose1() {
      this.dialogVisible1 = false
    },
    handleClose2() {
      this.dialogVisible2 = false
    },

    async getCode() {
      if (!this.form2.phone) {
        return
      }
      if (!this.timer) {
        this.count = 60
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
        try {
          const params = {
            phoneNumber: this.form2.phone,
            templateId: 1,
          }
          await getPhoneCode(params)
          this.$message.success('发送成功，请注意查收')
        } catch (err) {
          console.error(err)
        }
      }
    },

    async _getVerifyCode() {
      try {
        this.verifyUrl = await getVerifyCode()
      } catch (err) {
        console.error(err)
      }
    },

    changeVerifyCode() {
      this._getVerifyCode()
    },

    async _passWordLogin() {
      try {
        if (!this.checked) {
          this.$message.warning('请勾选服务协议')
        } else {
          const valid = await this.$refs.form.validate()
          if (valid) {
            const params = {
              type: 1,
              login: this.form.name,
              password: this.form.password,
              pictureCode: this.form.pictureCode,
            }
            const { menu, token, url } = await getLogin(params)
            this.$message.success('登录成功')
            this.setMenus(menu) // 存menu
            this.setToken(token) // 存token
            this.setUsername(params.login)
            console.log(url)
            window.location.href = url
          }
        }
      } catch (err) {
        console.error(err)
      }
    },

    async codeLogin() {
      try {
        if (!this.checked) {
          this.$message.warning('请勾选服务协议')
        } else {
          const valid = await this.$refs.form2.validate()
          if (valid) {
            const params = {
              pictureCode: this.form2.pictureCode,
              code: this.form2.code,
              login: this.form2.phone,
              type: 1,
            }
            const { menu, token, url } = await getLogin(params)
            this.$message.success('登录成功')
            this.setMenus(menu) // 存menu
            this.setToken(token) // 存token
            this.setUsername(params.login)
            window.location.href = url
          }
        }
      } catch (err) {
        console.error(err)
      }
    },
  },
}
</script>
<style scoped lang="scss">
// body {
//   position: relative;
// }
// .el-dialog__wrapper {
//   top: 10% !important;
// }
.login {
  min-width: 100%;
  min-height: 664px;
  background: #147fd2;
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;

    .bgImg {
      // padding: 68px 0 0 212px;
      position: relative;
      img {
        width: 612px;
        height: 484px;
      }

      span {
        position: absolute;
        top: 113px;
        left: 210px;
        width: 192px;
        height: 39px;
        font-size: 32px;
        font-family: FZLTZCHJW--GB1-0, FZLTZCHJW--GB1;
        font-weight: normal;
        color: #ffffff;
        line-height: 39px;
        // padding: 181px 0 0 422px;
      }
    }

    .login-right {
      width: 420px;
      height: 484px;
      background: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      position: relative;

      .login-code {
        padding: 44px 0 0 114px;

        span {
          display: inline-block;
          width: auto;
          height: 44px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
          line-height: 44px;
          text-align: center;
          margin-right: 16px;
        }
        span.active {
          color: #147fd2;
          border-bottom: 4px solid #147fd2;
          border-radius: 2px;
        }
      }

      .verifyImg {
        display: inline-block;
        width: 128px;
        height: 44px;
        position: absolute;
        top: 0;
        margin-left: 8px;
      }

      .el-form-item {
        margin-left: 36px;
        margin-top: 32px;

        .el-input {
          width: 348px;
          height: 44px;
        }
      }
      .forget {
        width: 56px;
        height: 24px;
        font-size: 14px;
        font-weight: 400;
        color: #147fd2;
        // line-height: 24px;
        margin-left: 321px;
        cursor: pointer;
        position: absolute;
        top: 322px;
      }
      .login-tips {
        padding-left: 36px;
        padding-top: 5px;
        display: flex;
        .tip {
          padding-left: 8px;
          width: 325px;
          height: 36px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          & > span:first-child,
          & > span:nth-child(2),
          & > span:nth-child(3) {
            color: #147fd2;
            cursor: pointer;
          }
        }
      }
      .login-button {
        width: 348px;
        height: 44px;
        background: #147fd2;
        border-radius: 8px;
        margin: 16px 0 0 36px;
      }
      .goRegister {
        margin: 16px 0 0 145px;
        width: 131px;
        height: 24px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 24px;
        & > span:first-child {
          color: #cccccc;
        }
        & > span:nth-child(2) {
          color: #147fd2;
          cursor: pointer;
        }
      }
      .getCode {
        display: inline-block;
        width: 128px;
        height: 44px;
        background: #147fd2;
        border-radius: 8px;
        color: #ffffff;
        text-align: center;
        line-height: 44px;
        font-weight: 400;
        margin-left: 8px;
        cursor: pointer;
      }

      .el-dialog__body {
        .el-form {
          .el-form-item {
            // margin: auto;
          }
        }
      }
    }
  }
}

.el-dialog {
  .bold {
    font-weight: bold;
  }
  p {
    line-height: 30px;
  }
}
</style>
